import * as React from "react";
import { Link } from "gatsby";
import { Page } from "../components";

import * as css from "../styles/pages/not-found.module.scss";

// markup
const NotFoundPage = () => {
  return (
    <Page metaTitle="Nie znaleziono!">
      <main className={css.main}>
        <h1>Niczego tu nie ma ;)</h1>
        <Link to="/">Strona główna</Link>.
      </main>
    </Page>
  );
};

export default NotFoundPage;
